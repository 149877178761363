<template>
  <div>
    <div class="header_common_box">
        <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>
    <div>
        <h4 class="title" v-if="lotteryKind === '5'">排列五长条</h4>
        <h4 class="title" v-if="lotteryKind === '7'">七彩星长条</h4>
        <img  :src="imgUrl"/>
    
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    components: {
        vHeader
    },
    data() {
        return {
        title: this.$route.meta.title,
        imgUrl: '',
        lotteryKind: '',
        }
    },
    async created() {
        this.lotteryKind = this.$route.query.lotteryKind
        const res = await this.$HTTP.post(this, 'API_LOTTERY_LATEST_LIST', { systemId:'100001'}, {}, true)
        res.data.filter((item=>{
            if(item.lotteryKind===parseInt(this.lotteryKind))
            this.imgUrl=item.fileUrl
        }))
        
    },
    mounted:function(){
        //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>

<style>
.title{
    margin:0.3rem;
    text-align: center;
    font-size: 0.5rem;
    line-height: 0.5rem;
}
img{
    width: 100%;
    height: auto;
}
</style>